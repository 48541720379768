import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { IPurchaseSocket } from '@core/interfaces/purchase/purchase.interface';

export enum THubMethods {
  ProductsCreated = 'productsCreated',
  PlanCreated = 'planCreated',
  PlanRegistered = 'planRegistered',
  PurchaseRegistered = 'purchaseRegistered',
  DocsUploaded = 'docsUploaded',
  MyDocsUploaded = 'myDocsUploaded',
  ClaimStatusChange = 'claimStatusChange',
  ProtectionPlanCreated = 'protectionPlanCreated',
  ProtectionPlanDocsUploaded = 'protectionPlanDocsUploaded',
  TechPhotosUploaded = 'techPhotosUploaded',
  TechPhotosDeleted = 'techPhotosDeleted',
  JewelryClaimCreated = 'jewelryClaimCreated',
  JewelryPlanCreated = 'jewelryPlanCreated',
}

export type THubEventName = THubMethods;

interface IHubMessageProductsCreated {
  name: THubMethods.ProductsCreated;
  data: any;
}

interface IHubMessagePlanCreated {
  name: THubMethods.PlanCreated;
  data: any;
}

interface IHubMessagePlanRegistered {
  name: THubMethods.PlanRegistered;
  data: {
    errorMessage: string;
    planInfo: PlanDetails;
  };
}

interface IHubMessagePurchaseRegistered {
  name: THubMethods.PurchaseRegistered;
  data: {
    errorMessage: string;
    planInfo: IPurchaseSocket;
  };
}

interface IHubMessageDocsUploaded {
  name: THubMethods.DocsUploaded;
  data: any;
}

interface IHubMessageClaimStatusChange {
  name: THubMethods.ClaimStatusChange;
  data: any;
}

interface IHubMessageProtectionPlanCreated {
  name: THubMethods.ProtectionPlanCreated;
  data: any;
}

interface IHubMessageMyDocsUploaded {
  name: THubMethods.MyDocsUploaded;
  data: {
    planGuid: string;
    planId: number;
  };
}

interface IHubMessageProtectionPlanDocsUploaded {
  name: THubMethods.ProtectionPlanDocsUploaded;
  data: any;
}

interface IHubMessageTechPhotosUploaded {
  name: THubMethods.TechPhotosUploaded;
  data: any;
}

interface IHubMessageTechPhotosDeleted {
  name: THubMethods.TechPhotosDeleted;
  data: any;
}

interface IHubMessageJewelryClaimCreated {
  name: THubMethods.JewelryClaimCreated;
  data: {
    claimInfo: {
      claimNumber: string;
    };
  };
}

interface IHubMessageJewelryPlanCreated {
  name: THubMethods.JewelryPlanCreated;
  data: any;
}

export type HubEvent =
  | IHubMessageProductsCreated
  | IHubMessagePlanCreated
  | IHubMessagePlanRegistered
  | IHubMessageDocsUploaded
  | IHubMessageClaimStatusChange
  | IHubMessageMyDocsUploaded
  | IHubMessageProtectionPlanCreated
  | IHubMessageProtectionPlanDocsUploaded
  | IHubMessagePurchaseRegistered
  | IHubMessageTechPhotosUploaded
  | IHubMessageTechPhotosDeleted
  | IHubMessageJewelryClaimCreated
  | IHubMessageJewelryPlanCreated;
