<section class="modal-dialog">
  <div class="modal-dialog__header">
    <h2 class="modal-dialog__title">
      {{ data.title }}
    </h2>
    <mat-icon
      *ngIf="hasClose"
      svgIcon="close"
      class="modal-dialog__close"
      (click)="close()"></mat-icon>
  </div>

  <div
    appCustomOverlayScrollbar
    class="modal-dialog__content">
    <div
      *ngIf="data.content"
      [innerHTML]="data.content"></div>
    <ng-content></ng-content>
  </div>

  <mat-dialog-actions
    *ngIf="showActions"
    align="end"
    class="modal-dialog__btn-wrapper">
    <a
      class="modal-dialog__btn"
      [href]="data.actionLink"
      [disabled]="disabledActionBtn"
      mat-flat-button
      color="primary"
      appLoadingButton
      [isLoading]="actionInProgress"
      *ngIf="data.actionLink">
      <mat-icon
        class="modal-dialog__btn-icon"
        *ngIf="data.actionLinkIcon">
        {{ data.actionLinkIcon }}
      </mat-icon>
      {{ data.actionLinkName }}
    </a>
    <button
      class="modal-dialog__btn"
      mat-stroked-button
      color="primary"
      [disabled]="actionInProgress"
      (click)="close()"
      [mat-dialog-close]="false">
      {{ data.closeBtnName || "Close" }}
    </button>
    <button
      class="modal-dialog__btn"
      [disabled]="disabledActionBtn"
      mat-flat-button
      color="primary"
      appLoadingButton
      [isLoading]="actionInProgress"
      *ngIf="data.actionBtnName"
      (click)="action()">
      {{ data.actionBtnName }}
    </button>
  </mat-dialog-actions>
</section>
