/// <summary>
/// Claim statuses to use in portal - NOTE: not a 1-1 with CRM statuses
/// </summary>
export enum ClaimStatuses {
  /// <summary>
  /// Open
  /// </summary>
  Pending = 1,
  /// <summary>
  /// Open
  /// </summary>
  Open = 2,
  /// <summary>
  /// Closed
  /// </summary>
  Closed = 3,
  /// <summary>
  /// Cancelled or deleted
  /// </summary>
  Cancelled = 99,
}

export const claimStatusToTitle = {
  [ClaimStatuses.Pending]: 'Pending',
  [ClaimStatuses.Open]: 'Open',
  [ClaimStatuses.Closed]: 'Closed',
  [ClaimStatuses.Cancelled]: 'Cancelled',
};

export const CLAIM_STATUS_ITEMS_LIST: {id: ClaimStatuses; name: string}[] = [
  { id: ClaimStatuses.Pending, name: claimStatusToTitle[ClaimStatuses.Pending] },
  { id: ClaimStatuses.Open, name: claimStatusToTitle[ClaimStatuses.Open] },
  { id: ClaimStatuses.Closed, name: claimStatusToTitle[ClaimStatuses.Closed] },
  // {id: ClaimStatuses.Cancelled, name: claimStatusToTitle[ClaimStatuses.Cancelled]},
];
