import { Feature } from '../enums/feature.enum';

export const RouterConstants = {
  forbidden: '/app/forbidden',
  claims: 'app/claims',
  dashboard: 'app/dashboard',
  admin: 'app/admin',
  claimDocuments: 'app/claim-documents',
  features: {
    admin: [Feature.ViewUsers, Feature.ViewRegions, Feature.ManageRegions],
    techAdmin: [Feature.ViewUsers],
    tenants: [Feature.ManageTenants, Feature.ViewTenants, Feature.UpdateTenantVisuals],
    sales: [Feature.HasReports],
  },
};
