import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TBreakpoint } from '../enums/breakpoint.enum';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  isMobile$ = this._buildBreakpointObservable(TBreakpoint.Mobile);
  isMobileLarge$ = this._buildBreakpointObservable(TBreakpoint.MobileLarge);
  isMobileOrTablet$ = this._buildBreakpointObservable(TBreakpoint.Mobile, TBreakpoint.MobileLarge, TBreakpoint.Tablet);
  isTablet$ = this._buildBreakpointObservable(TBreakpoint.Tablet);
  isTabletLarge$ = this._buildBreakpointObservable(TBreakpoint.TabletLarge);
  isDesktop$ = this._buildBreakpointObservable(TBreakpoint.Desktop);

  fromMobileLarge$ = this._buildBreakpointObservable(TBreakpoint.FromMobileLarge);
  fromTablet$ = this._buildBreakpointObservable(TBreakpoint.FromTablet);
  fromTabletLarge$ = this._buildBreakpointObservable(TBreakpoint.FromTabletLarge);
  fromDesktop$ = this._buildBreakpointObservable(TBreakpoint.FromDesktop);

  toMobileLarge$ = this._buildBreakpointObservable(TBreakpoint.ToMobileLarge);
  toTablet$ = this._buildBreakpointObservable(TBreakpoint.ToTablet);
  toTabletLarge$ = this._buildBreakpointObservable(TBreakpoint.ToTabletLarge);
  toDesktop$ = this._buildBreakpointObservable(TBreakpoint.ToDesktop);

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  isBreakpoint(...breakpoint: TBreakpoint[]): boolean {
    return this.breakpointObserver.isMatched(<any>breakpoint);
  }

  private _buildBreakpointObservable(...breakpoint: TBreakpoint[]): Observable<boolean> {
    return this.breakpointObserver.observe(<any>breakpoint).pipe(map(state => state.matches));
  }
}
