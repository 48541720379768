import { HttpEvent, HttpHandlerFn , HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { RouterConstants } from '../constants/router.constants';
import { AuthService } from '../services/auth.service';

export function UnauthorizedInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const messageDefault = 'There was an unexpected error processing the request. Please report the issue or try again.';
  const authService = inject(AuthService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  if (authService.refreshTokenInProgress$.getValue()) {
    return authService.refreshTokenSubject$.pipe(
      filter(result => result !== null),
      take(1),
      switchMap(() => authService.setDefaultHttpHeaders(request, next)),
    );
  } else {
    if (request.url.includes('renew')) {
      authService.refreshTokenInProgress$.next(true);
      authService.refreshTokenSubject$$.next(null);
    }
    return next(request).pipe(
      catchError(error => {
        if (request.url.includes('renew')) {
          return throwError(error);
        }

        if (error.status === 0) {
          notificationService.notification$.next({
            message: messageDefault,
            type: NotificationType.Error,
            duration: 8000,
          });
          return throwError(error);
        } else if (error.status === 401) {
          if (!authService.refreshTokenInProgress$.getValue()) {
            authService.refreshToken().subscribe();
          }
          return authService.refreshTokenSubject$.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => authService.setDefaultHttpHeaders(request, next)),
          );
        } else if (error.status === 403) {
          router.navigate([RouterConstants.forbidden]);
          return EMPTY;
        } else {
          notificationService.nextError(error, request);
          return throwError(error);
        }
      }),
    );
  }
}
