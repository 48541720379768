import { Injectable, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ARGBToRgbaPipe implements PipeTransform {
  transform(argb: string, opacity?: string): string {
    const hex = `#${argb.substring(2, argb.length)}`;
    if (hex.length === 7) {
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        return this.hexTransform(hex, argb, opacity);
      }
    } else {
      const newHex = '#' + hex.substring(2, hex.length+1);
      if (hex.length === 8 && (/^#([A-Fa-f0-9]{3}){1,2}$/.test(newHex))) {
        return this.hexTransform(newHex, argb, opacity);
      }
    }
    throw new Error('Bad Hex:  ' + argb);
  }

  hexTransform(hex: string, argb: string, opacity?: string) {
    const a = argb.substring(0, 2);
    const alphaDec = this.hexToDec(a);
    const alpha = opacity || (alphaDec / 255).toFixed(2);
    let c;

    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    /* tslint:disable:no-bitwise */
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
  }

  hexToDec(hex: string) {
    let i;
    const parts = hex.length;

    let decimal = 0;
    let pow = parts - 1;

    for (i = 0; i < parts; i++) {
      const base = Math.pow(16, pow);
      let hexPart = hex[i];
      if (Number.isNaN(+hexPart)) {
        hexPart = this.alphToNum(hexPart);
      }
      decimal += +base * +hexPart;
      pow--;
    }
    return decimal;
  }

  alphToNum(hexDigit: string) {
    let digit;
    switch (hexDigit.toLowerCase()) {
      case 'a':
        digit = 10;
        break;
      case 'b':
        digit = 11;
        break;
      case 'c':
        digit = 12;
        break;
      case 'd':
        digit = 13;
        break;
      case 'e':
        digit = 14;
        break;
      case 'f':
        digit = 15;
        break;
      default:
        digit = 0;
        break;
    }

    return digit;
  }
}
