<div class="not-found">
  <div class="not-found__image-section">
    <mat-icon class="not-found__image-couch" svgIcon="404-couch"></mat-icon>
  </div>
  <div class="not-found__main-text block__content">
    <p>404</p>
  </div>
  <div class="not-found__description block">
    <p>We can’t seem to find what you’re looking for.</p>
    <p>We even looked under the cushions.</p>
  </div>
  <div class="not-found__actions block">
    <button class="inline-buttons__button _common-button--rounded color-primary"
            (click)="goHome()"
            mat-flat-button color="primary">{{buttonCaption}}</button>
    <p>
    </p>
  </div>
</div>
