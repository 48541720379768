import { ErrorHandler, Injectable } from '@angular/core';

import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
  constructor(private applicationInsightsService: ApplicationInsightsService) {}

  handleError(error: Error) {
    this.applicationInsightsService.logException(error);
    console.error(error);
  }
}
