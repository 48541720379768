export enum UserScope {
  InternalSystem = 1000,
  System = 100,
  Shared = 200,
  Retailer = 300,
  Consumer = 400,
  Tech = 500,
  Public = 600,
  Jewelry = 700,
}
