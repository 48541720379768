import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  buildNumber$ = new BehaviorSubject<string>(null);

  constructor() {}
}
