import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TProductType } from '../../../../th-mjc/app/core/enums/product-type.enum';
import { UserScope } from '../enums/user-scope.enum';
import { IContactInfo } from '../interfaces/contact-info/contact-info.interface';
import { StaticListItem } from '../interfaces/reports/reports.interface';
import { Timezone } from '../interfaces/session/session-data.interface';
import { IState } from '../interfaces/state.interface';
import { IServerUserRole, IUserRole } from '../interfaces/user/role.interface';
import { Identity, IIdentity } from '../interfaces/util/identity.interface';
import { CrudApiBaseService } from './crud-api.base';

export interface ITechReportLookup {
  reasonNotComplete: IIdentity[];
  mattressFirmness: IIdentity[];
  mattressQuiltType: IIdentity[];
  frameType: IIdentity[];
  mattressIssues: IIdentity[];
  boxSpringIssues: IIdentity[];
  problemTypes: IIdentity<string>[];
  causeTypes: IIdentity<string>[];
}

@Injectable({
  providedIn: 'root',
})
export class LookupApiService extends CrudApiBaseService<Identity> {
  endpoint = 'lookup';

  lookupEscalation(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('escalation'));
  }

  lookupLanguage(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('language'));
  }

  getContactLanguage(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`language/${id}`));
  }

  lookupContactMethod(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('contactmethod'));
  }

  getContactMethod(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`contactmethod/${id}`));
  }

  lookupContactTime(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('contacttime'));
  }

  getContactTime(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`contacttime/${id}`));
  }

  lookupSurfaceType(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('surfacetype'));
  }

  getSurfaceType(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`surfacetype/${id}`));
  }

  lookupPartsAvailability(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('partsavailability'));
  }

  getPartsAvailability(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`partsavailability/${id}`));
  }

  lookupProductAvailability(): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl('productavailability'));
  }

  getProductAvailability(id: number): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl(`productavailability/${id}`));
  }

  lookupProductType(productClassificationId: number): Observable<Identity[]> {
    return this.httpClient.get<Identity[]>(this.getUrl(`${productClassificationId}/producttype`));
  }

  lookupTimezones(): Observable<Timezone[]> {
    return this.httpClient.get<Timezone[]>(this.getUrl('timezone'));
  }

  lookupContactInfo() {
    return this.httpClient.get<IContactInfo[]>(this.getUrl('contact-information'));
  }

  lookupAgreementType(): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl('agreementtype'));
  }

  lookupServiceActionType(): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl('serviceactiontype'));
  }

  lookupRetailerClassification(): Observable<StaticListItem> {
    return this.httpClient.get<StaticListItem>(this.getUrl('retailerclassification'));
  }

  lookupTechnician(): Observable<Identity> {
    return this.httpClient.get<Identity>(this.getUrl('technician'));
  }

  lookupProblemCause() {
    return this.httpClient.get<Identity[]>(this.getUrl('problemcause'));
  }

  lookupStates() {
    return this.httpClient.get<IState[]>(this.getUrl('states'));
  }

  lookupTechReport() {
    return this.httpClient.get<ITechReportLookup>(this.getUrl('techreport'));
  }

  lookupRoles() {
    return this.httpClient.get<IUserRole[]>(this.getUrl('roles'));
  }

  getRepairServicerType(): Observable<IIdentity[]> {
    return this.httpClient.get<IIdentity[]>(this.getUrl('repairservicingtype'));
  }

  getMjcProductTypes(): Observable<IIdentity<TProductType>[]> {
    return this.httpClient.get<IIdentity<TProductType>[]>(this.getUrl('mjcproducttype'));
  }

  getProductMaterialTypes(): Observable<IIdentity[]> {
    return this.httpClient.get<IIdentity[]>(this.getUrl('productmaterialtype'));
  }

  lookupRolesByScope(scope: UserScope, userRole: number): Observable<IUserRole[]> {
    return this.httpClient.get<IServerUserRole[]>(this.getUrl(`roles/${scope}`)).pipe(
      map(roles =>
        roles.map(role => ({
          ...role,
          selected: false,
          disabled: role.id < userRole,
        })),
      ),
    );
  }
}
