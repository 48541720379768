export enum TConsumerPlanType {
  /// <summary>
  /// My service consumer type
  /// </summary>
  MyService = 1,
  /// <summary>
  /// Protection plan consumer plan
  /// </summary>
  ProtectionPlan = 2,
  /// <summary>
  /// Unsupported
  /// </summary>
  Unsupported = 99,
}

export const consumerPlanTypeToTitle = {
  [TConsumerPlanType.MyService]: 'My Service',
  [TConsumerPlanType.ProtectionPlan]: 'Protection Plan',
};

export const CONSUMER_PLAN_TYPE_ITEMS_LIST: {id: TConsumerPlanType; name: string}[] = [
  { id: TConsumerPlanType.MyService, name: consumerPlanTypeToTitle[TConsumerPlanType.MyService] },
  { id: TConsumerPlanType.ProtectionPlan, name: consumerPlanTypeToTitle[TConsumerPlanType.ProtectionPlan] },
];
