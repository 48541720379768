export enum TPage {
  Initial = 'contact-info', // Could be changed
  App = 'app',
  Dashboard = 'dashboard',
  Admin = 'admin',
  Claims = 'claims',
  ClaimDocuments = 'claim-documents',
  ClaimFiles = 'claim-files',
  ClaimFilesUpload = 'claim-files-upload',
  Plans = 'plans',
  Regions = 'regions',
  Portals = 'portals',
  Sales = 'sales',
  ContactInfo = 'contact-info',
  Help = 'help',
  Videos = 'videos',
  FAQ = 'faq',
  Support = 'support',
  Users = 'users',
  QuickSearch = 'quick-search',
  Reports = 'reports',
  UserProfile = 'user-profile',
  LoginAsTenant = 'login-as-tenant',
  LogOutAsTenant = 'log-out-as-tenant',
  DataImport = 'data-import',
  SlumberlandData = 'slumberland-data',
  ImageArchive = 'image-archive',

  //LOGIN AS
  LoginAsConsumer = 'login-as-consumer',

  // CONSUMER PORTAL
  MyClaims = 'my-claims',
  MyPlans = 'my-plans',
  RegisterClaim = 'register-claim',

  // SYSTEM PORTAL
  RetailerConsumerPortal = 'retailer-consumer',
  TechnicianPortal = 'technician',
  MjcPortal = 'mjc',

  // PORTALS
  MyService = 'my-service',

  // CRUD
  Add = 'add',

  // PUBLIC
  Forgot = 'forgot',
  Login = 'login',
  LogOut = 'log-out',
  ResendActivation = 'resend-activation',

  // ERROR
  Forbidden = 'forbidden',
  NotFound = '404',

  // TECH-PORTAL
  WorkOrders = 'work-orders',
  Prequals = 'prequals',
  Invoices = 'invoices',
  Contact = 'contact',
  TechReport = 'tech-report',

  // MJC-PORTAL
  PoPList = 'pop-list',
}
