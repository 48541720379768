export enum SplashLayouts {
  /// <summary>
  /// Like Arhaus
  /// </summary>
  WideRightJustified = 1,
  /// <summary>
  /// Like Pricepoint
  /// </summary>
  NormalRightJustified = 2,
  /// <summary>
  /// Like Conn's
  /// </summary>
  NormalWithRightMargin = 3,
}
