import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { importProvidersFrom, isDevMode, Type } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { bootstrapApplication } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { buildVariables } from '@th-common/assets/config/build-variables';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { HttpAuthInterceptor } from '@core/interceptors/http-auth.interceptor';
import { UnauthorizedInterceptor } from '@core/interceptors/unauthorize.interceptor';
import { IAppConfig } from '@core/interfaces/app-config.interface';

export function bootstrapMontageApp(
  appComponent: Type<any>,
  appRootConfig: any,
  portalType: TPortalType,
  useGA = false,
): void {
  // eslint-disable-next-line max-len
  fetch(`/assets/config/${isDevMode() ? `environment.${buildVariables.environmentName}` : 'environment'}.json?v=${buildVariables.buildNumber.replace(/\D/g, '')}`)
    .then((response) => response.json())
    .then((config: IAppConfig) => {
      bootstrapApplication(appComponent, {
        ...appRootConfig,
        providers: [
          ...appRootConfig.providers,
          importProvidersFrom(
            MatDialogModule,
            MatSelectModule,
            MatAutocompleteModule,
            MatChipsModule,
            MatTooltipModule,
            MatDatepickerModule,
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: !isDevMode() }),
            !isDevMode() && useGA ? [
              NgxGoogleAnalyticsModule.forRoot(config.portalGA),
              NgxGoogleAnalyticsRouterModule,
            ] : [],
          ),
          provideHttpClient(
            withInterceptors([
              HttpAuthInterceptor,
              UnauthorizedInterceptor,
            ]),
          ),
          {
            provide: APP_CONFIG,
            useValue: {
              ...config,
              portalType,
            },
          },
        ],
      })
        .then(() => {
          if ('serviceWorker' in navigator && !isDevMode()) {
            navigator.serviceWorker.register('/ngsw-worker.js');
          }
        })
        .catch(err => console.warn(err));
    });
}
