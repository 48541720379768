export enum TBreakpoint {
  Mobile = <any>'(max-width: 480px)',
  MobileLarge = <any>'(min-width: 481px) and (max-width: 768px)',
  Tablet = <any>'(min-width: 769px) and (max-width: 1023px)',
  TabletLarge = <any>'(min-width: 1024px) and (max-width: 1279px)',
  Desktop = <any>'(min-width: 1280px)',

  FromMobileLarge = <any>'(min-width: 481px)',
  FromTablet = <any>'(min-width: 769px)',
  FromTabletLarge = <any>'(min-width: 1024px)',
  FromDesktop = <any>'(min-width: 1280px)',

  ToMobileLarge = <any>'(max-width: 768px)',
  ToTablet = <any>'(max-width: 1023px)',
  ToTabletLarge = <any>'(max-width: 1279px)',
  ToDesktop = <any>'(min-width: 1280px)',
}

export enum TFileBreakpoint {
  Mobile = '768',
  Tablet = '1024',
  Desktop = '1280',
}
