import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PasswordOptionsInterface } from '../interfaces/password/password-options.interface';
import { Credentials } from '../interfaces/session/credentials.interface';
import { ISession } from '../interfaces/session/session-data.interface';
import { ResetPassword } from '../interfaces/user-profile/reset-password.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({ providedIn: 'root' })
export class TokenApi extends CrudApiBaseService<any> {
  endpoint = 'token';

  acquire(credentials: Credentials) {
    return this.httpClient.post<ISession>(this.getUrl(''), credentials);
  }

  renew(refreshToken: string) {
    return this.httpClient.get<ISession>(this.getUrl(`renew?refresh=${refreshToken}`));
  }

  resetPassword(credentials: ResetPassword) {
    return this.httpClient.put<ISession>(this.getUrl('resetpassword'), credentials);
  }

  activateAccount(credentials: ResetPassword, url: string) {
    return this.httpClient.put<ISession>(this.getUrl(url), credentials);
  }

  passwordOptions(): Observable<PasswordOptionsInterface> {
    return this.httpClient.get<PasswordOptionsInterface>(this.getUrl('passwordoptions'));
  }

  logout(): Observable<any> {
    return this.httpClient.delete(this.getUrl(''));
  }

  loginAs(tenantId: number, token: string, userId: number = null) {
    return this.httpClient.get<ISession>(this.getUrl('loginas'), {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-TenantId': tenantId.toString(),
        'X-UserId': userId ? userId.toString() : '',
      },
    });
  }
}
