import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { TenantNameResolverService } from '@core/services/tenant-name-resolver.service';

@Component({
  templateUrl: 'redirect.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnInit {
  appConfig = inject(APP_CONFIG);
  logoUrl = this.appConfig.portalType === TPortalType.MJC
    ? '../../../../assets/montage-logos/MJC-One80-White.svg' : '../../../../assets/montage-logos/MFS-One80-White.svg';

  constructor(private readonly tenantNameResolver: TenantNameResolverService, private readonly router: Router) {}

  ngOnInit(): void {
    const tenant = this.tenantNameResolver.resolveTenantName();
    this.router.navigate([tenant]);
  }
}
