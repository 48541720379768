import { ILogger, LogLevel } from '@microsoft/signalr';

import { ApplicationInsightsService } from '../../application-insights/application-insights.service';

export class SignalRLogger implements ILogger {
  private readonly minimumLogLevel: LogLevel;
  private readonly minimumAILogLevel: LogLevel;
  private readonly applicationInsightsService: ApplicationInsightsService;

  constructor(
    minimumLogLevel: LogLevel,
    minimumAILogLevel: LogLevel,
    applicationInsightsService: ApplicationInsightsService,
  ) {
    this.minimumLogLevel = minimumLogLevel;
    this.minimumAILogLevel = minimumAILogLevel;
    this.applicationInsightsService = applicationInsightsService;
  }

  log(logLevel: LogLevel, message: string) {
    if (logLevel >= this.minimumLogLevel) {
      message = message || 'SignalR connection was failed with unknown reason';
      const errorMessage = `[SignalR] Log ${LogLevel[logLevel]}: ${message}`;
      if (logLevel >= this.minimumAILogLevel) {
        const error = new Error(errorMessage);
        this.applicationInsightsService.logException(error);
      }
      switch (logLevel) {
        case LogLevel.Critical:
        case LogLevel.Error:
          console.error(`[${new Date().toISOString()}] ${errorMessage}`);
          break;
        case LogLevel.Warning:
          console.warn(`[${new Date().toISOString()}] ${errorMessage}`);
          break;
        case LogLevel.Information:
          console.log(`[${new Date().toISOString()}] ${errorMessage}`);
          break;
        default:
          console.log(`[${new Date().toISOString()}] ${errorMessage}`);
          break;
      }
    }
  }
}
