export enum SplashLogoAlignments {
  /// <summary>
  /// Logo on top left of login overlay
  /// </summary>
  TopLeft = 1,
  /// <summary>
  /// Logo on top center of login overlay
  /// </summary>
  TopCenter = 2,
  /// <summary>
  /// Logo on top right of login overlay
  /// </summary>
  TopRight = 3,
  /// <summary>
  /// Logo on left of login overlay
  /// </summary>
  Left = 4,
  /// <summary>
  /// Logo on center of login overlay
  /// </summary>
  Center = 5,
  /// <summary>
  /// Logo on right of login overlay
  /// </summary>
  Right = 6,
}
