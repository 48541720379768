import {
  MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS,
  MatLegacyRadioDefaultOptions as MatRadioDefaultOptions,
} from '@angular/material/legacy-radio';

const matRadioDefaultOptions: MatRadioDefaultOptions = {
  color: 'primary',
};

export const FA_RADIO_DEFAULT_OPTIONS = {
  provide: MAT_RADIO_DEFAULT_OPTIONS,
  useValue: matRadioDefaultOptions,
};
