import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import random from 'lodash-es/random';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { from, interval } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private readonly _config = Object.freeze({
    serviceName: 'VersionUpdate', // Is used for debug purpose in the console
    pollingInterval: 10 * 60 * 1000, // 10m,
    reloadMaxAppRunningTime: 10, // During this time after start app would be reloaded immediately without a notification
  });

  private readonly _appInitMoment: Moment = moment();

  private _appHash: string | Int32Array;

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly updates: SwUpdate,
    private readonly modalDialogService: ModalDialogService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Starts polling for a new version of the app
   */
  startVersionPolling(): void {
    if (!this.updates.isEnabled) {
      console.warn(this._config.serviceName, 'Service Worker is not supported by a browser');
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'Waiting until app is stable...');
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    // TODO: Check why app is not stable in prod mode
    // const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const pollingInterval$ = interval(this._config.pollingInterval);

    // appIsStable$.subscribe(isStable => {ß
    //   // eslint-disable-next-line no-restricted-syntax
    //   console.info(this._config.serviceName, 'app isStable', isStable);
    // });
    this.checkUpdates();

    pollingInterval$
      // eslint-disable-next-line no-restricted-syntax
      .pipe(
        tap(() =>
          // eslint-disable-next-line no-restricted-syntax
          console.info(this._config.serviceName, 'Checking for a new version started', new Date().toISOString()),
        ),
      )
      .pipe(
        switchMap(() => from(this.updates.checkForUpdate())),
      )
      .subscribe(updateAvailable => {
        if (updateAvailable) {
          this._onAvailable();
        }
        // this._checkNewVersion(); // Custom new version check
        // eslint-disable-next-line no-restricted-syntax
        console.info(this._config.serviceName, 'Checking for a new version finished', new Date().toISOString());
      });
  }

  /**
   * Handles upgrade to a newer version of the app
   * Switches immediately if app has been started recently
   * Shows a prompt if app is already running for some time
   */
  private _onAvailable(): void {
    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'New app version is available!', event);
    const appRunningTime = moment().diff(this._appInitMoment, 'seconds');

    // Switch to a newer version immediately if app has been started recently
    if (appRunningTime < this._config.reloadMaxAppRunningTime) {
      // eslint-disable-next-line no-restricted-syntax
      console.info(
        this._config.serviceName,
        'Switching to a new version immediately',
        `Running time: ${appRunningTime}`,
      );
      this._switchToNewVersion();
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'Switch to a new version message shown', `Running time: ${appRunningTime}`);
    this.modalDialogService.open({
      title: 'New version available!',
      content: 'We have published a new version of our portal. Click \'Update now!\' to upgrade and refresh the site or \'Close\' to keep using the current version (not recommended).',
      actionBtnName: 'Update now!',
      actionCallback: () => {
        this._switchToNewVersion();
      },
    }, null, {
      maxWidth: 'calc(100vw - 64px)',
      disableClose: true,
    });
    this.appRef.tick();
  }

  checkUpdates() {
    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'Checking for a new version started', new Date().toISOString());
    this.updates
      .checkForUpdate()
      .then(updateAvailable => {
        // eslint-disable-next-line no-restricted-syntax
        console.info(this._config.serviceName, 'Checking for a new version finished', new Date().toISOString());
        if (updateAvailable) {
          this._onAvailable();
        }
        // this._checkNewVersion();
      });
  }

  /**
   * Is called AFTER upgrade to a new version
   */
  private _onActivated(): void {
    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'Switched to a new app version!', event);
  }

  /**
   * Activates new version of the app and reloads the page
   */
  private _switchToNewVersion(): void {
    // eslint-disable-next-line no-restricted-syntax
    console.info(this._config.serviceName, 'Start switching to a new app version...');
    this.updates.activateUpdate().then(success => {
      if (success) {
        // eslint-disable-next-line no-restricted-syntax
        console.info(this._config.serviceName, 'Switched to a new app version!', event);
        document.location.reload();
      } else {
        // eslint-disable-next-line no-restricted-syntax
        console.info(this._config.serviceName, 'No updates available!', event);
      }
    });
  }

  /**
   * Check app new version by comparing cached ngsw.json with a server one.
   */
  private _checkNewVersion(): void {
    this.http.get('/ngsw.json?anti-cache=' + random(100000)).subscribe((data: any) => {
      const hash = Md5.hashStr(JSON.stringify(data.hashTable));
      // eslint-disable-next-line no-restricted-syntax
      console.info(this._config.serviceName, this._appHash, hash);
      if (!this._appHash) {
        this._appHash = hash;
        return;
      }
      if (this._appHash !== hash) {
        this._appHash = hash;
        this._onAvailable();
      }
    });
  }
}
