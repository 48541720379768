export enum Feature {
  /// <summary>
  /// Add/Edit/Delete tenants
  /// </summary>
  ManageTenants = 1,
  /// <summary>
  /// View list of tenants
  /// </summary>
  ViewTenants = 2,
  /// <summary>
  /// Update splash logo etc
  /// </summary>
  UpdateTenantVisuals = 3,
  /// <summary>
  /// Get info on system configuration
  /// </summary>
  ViewSystemInfo = 4,
  /// <summary>
  /// View filebound images/documents for any tenant
  /// </summary>
  ViewAllFiles = 5,
  /// <summary>
  /// User can act as if they are logged into any tenant
  /// </summary>
  ActAsOtherTenant = 6,
  /// <summary>
  /// Import data through the data import API
  /// </summary>
  ImportData = 7,
  /// <summary>
  /// The active myService agreement supports service action type Complaints
  /// </summary>
  HasActiveMyServiceComplaints = 10,
  /// <summary>
  /// The active myService agreement supports service action type Settlements
  /// </summary>
  HasActiveMyServiceSettlements = 11,
  /// <summary>
  /// The active myService agreement supports service action type Parts
  /// </summary>
  HasActiveMyServiceParts = 12,
  /// <summary>
  /// The active myService agreement supports service action type Exchanges
  /// </summary>
  HasActiveMyServiceExchanges = 13,
  /// <summary>
  /// The active myService agreement supports service action type Kits
  /// </summary>
  HasActiveMyServiceKits = 14,
  /// <summary>
  /// The active myService agreement supports service action type Tech Visits
  /// </summary>
  HasActiveMyServiceTech = 15,
  /// <summary>
  /// The active myService agreement supports service action type Pre-qualifications
  /// </summary>
  HasActiveMyServicePrequalifications = 16,
  /// <summary>
  /// Tenant has protection plans and user is in ProtectionPlan group
  /// </summary>
  HasProtectionPlans = 17,
  /// <summary>
  /// Tenant has myService and user is in myService group
  /// </summary>
  HasMyService = 18,
  /// <summary>
  /// Tenant has the Claims for Client Review Process
  /// </summary>
  HasClaimsForClientReview = 20,
  /// <summary>
  /// Tenant has the Claims for FPP Client Review Process
  /// </summary>
  HasFppClaimsForClientReview = 21,
  /// <summary>
  /// Has access to at least one report
  /// </summary>
  HasReports = 30,
  /// <summary>
  /// Configure own tenant
  /// </summary>
  ManageOwnTenant = 100,
  /// <summary>
  /// Add/Edit/Delete users
  /// </summary>
  ManageUsers = 101,
  ActAsUser = 103,
  /// <summary>
  /// Deactivate or reactivate an account
  /// </summary>
  ChangeAccountActivation = 102,
  /// <summary>
  /// ManageUserGroups
  /// </summary>
  ManageUserGroups = 120,
  /// <summary>
  /// Manage resending activation email
  /// </summary>
  ManageUserActivationEmail = 130,
  /// <summary>
  /// Manage regions
  /// </summary>
  ManageRegions = 200,
  /// <summary>
  /// Manage No Portal Tenants
  /// </summary>
  ManageNoPortalTenants = 250,
  /// <summary>
  /// View users
  /// </summary>
  ViewUsers = 201,
  /// <summary>
  /// View regions
  /// </summary>
  ViewRegions = 202,
  /// <summary>
  /// View Claims
  /// </summary>
  ViewClaims = 300,
  /// <summary>
  /// Create a claim from scratch, manually
  /// </summary>
  CreateManualClaim = 301,
  /// <summary>
  /// Create a claim with search
  /// </summary>
  CreateClaimWithSearch = 302,
  /// <summary>
  /// Acknowledge a complaint service action
  /// </summary>
  AcknowledgeMyServiceComplaint = 303,
  /// <summary>
  /// Acknowledge a settlement service action
  /// </summary>
  AcknowledgeMyServiceSettlement = 304,
  /// <summary>
  /// Acknowledge a parts service action
  /// </summary>
  AcknowledgeMyServiceParts = 305,
  /// <summary>
  /// Acknowledge ane exchange service action
  /// </summary>
  AcknowledgeMyServiceExchange = 306,
  /// <summary>
  /// Clear claims for client review flag
  /// </summary>
  ReviewMyServiceClaim = 307,
  /// <summary>
  /// View a tech report
  /// </summary>
  ViewTechReport = 308,
  /// <summary>
  /// Can add files to a claim
  /// </summary>
  UploadClaimFiles = 310,
  /// <summary>
  /// Can add notes to a claim
  /// </summary>
  AddClaimNotes = 311,
  /// <summary>
  /// Can register protection plans (some store has fulfillment = OLP)
  /// </summary>
  RegisterOnlineProtectionPlan = 312,
  /// <summary>
  /// Can cancel protection plan
  /// </summary>
  CancelProtectionPlan = 313,
  /// <summary>
  /// Can add files to any claim
  /// </summary>
  UploadAnyClaimFiles = 320,
  /// <summary>
  /// Can see the dashboard
  /// </summary>

  DeleteClaimFiles = 321,
  SearchImageArchive = 322,

  ViewSmsHistory = 323,

  ViewDashboard = 400,
  /// <summary>
  /// The edit consumer
  /// </summary>
  EditConsumer = 420,
  /// <summary>
  /// Quick search
  /// </summary>
  QuickSearch = 500,
  /// <summary>
  /// Consumer view own claims
  /// </summary>
  ViewMyClaims = 600,
  /// <summary>
  /// Consumer view own plans
  /// </summary>
  ViewMyPlans = 601,

  AddNewProductsToClaim = 602,

  /// <summary>
  /// Part orders user tile
  /// </summary>
  HasPPPartOrders = 701,
  /// <summary>
  /// Replacements user tile
  /// </summary>
  HasPPReplacements = 702,
  /// <summary>
  /// Acknowledge a Part orders
  /// </summary>
  AcknowledgePPOrderParts = 703,
  /// <summary>
  /// The acknowledge pp request payment part orders
  /// </summary>
  AcknowledgePPRequestPaymentPartOrders = 704,
  /// <summary>
  /// The acknowledge confirm availability
  /// </summary>
  AcknowledgeConfirmAvailability = 705,
  /// <summary>
  /// Acknowledge ane exchange service action
  /// </summary>
  AcknowledgePPRequestPaymentReplacements = 706,
  /// <summary>
  /// Manage Data Import for Finance
  /// </summary>
  ManageDataImport = 800,

  /// View Clients from IdentityServer, set client secrets
  /// </summary>
  ManageClients = 810,

  /// <summary>
  /// View Historical Data
  /// </summary>
  ViewHistoricalData = 820,

  /// <summary>
  /// Manage data import data mappings
  /// </summary>
  ManageLocationMappings = 830,

  HasJewelryPlans = 907,
  HasWatchPlans = 908,

  /// Manage FAQs in System portal
  ManageFAQs = 1000,

  /// Manage Training videos in System portal
  ManageTrainingVideos = 1001,

  /// Manage Enhancements in System portal
  ManageEnhancements = 1002
}
