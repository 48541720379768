import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';

const matCheckboxDefaultOptions: MatCheckboxDefaultOptions = {
  color: 'primary',
};

export const FA_CHECKBOX_DEFAULT_OPTIONS = {
  provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
  useValue: matCheckboxDefaultOptions,
};
