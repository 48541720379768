import { inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { ISession } from '../../interfaces/session/session-data.interface';
import { HubEvent, THubEventName, THubMethods } from '../../models/hub-event.model';
import { UserStore } from '../../store/user/user.store';
import { ApplicationInsightsService } from '../application-insights/application-insights.service';
import { AuthService } from '../auth.service';
import { SignalRLogger } from './handlers/signalr-logger';

const DEFAULT_RETRY_DELAYS_IN_MILLISECONDS = [1000, 4000, 10000, null];

@Injectable()
export class BaseHub {
  connected$ = new BehaviorSubject<boolean>(false);
  eventBus$$ = new Subject<HubEvent>();
  eventBus$ = this.eventBus$$.asObservable();

  private _retryCount = 0;
  private _isSubscribedToOnlineEvent = false;
  private _isSubscribedToOfflineEvent = false;

  // Hubs properties
  private _hubConnection: HubConnection;
  private _hubName: string;
  private _events: THubEventName[];

  private _connectionInProgress = false;
  private readonly _appConfig = inject(APP_CONFIG);

  constructor(
    protected authService: AuthService,
    protected readonly userStore: UserStore,
    private readonly notification: NotificationService,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {}

  setConfig(hubName: string, events: THubEventName[]): void {
    this._hubName = hubName;
    this._events = events;

    this.authService.authorized$.subscribe(authorized => {
      if (!authorized) {
        this.stopSignalR();
      }
    });

    this.authService.tokenRefreshed$.subscribe(refreshed => {
      if (!refreshed) {
        return;
      }

      console.warn('SignalR token refreshed... Reconnecting to hub with the new token.');
      this.stopSignalR().then(() => this._reconnectToHub());
    });
  }

  invoke(hubEvent: HubEvent): void {
    if (this._isHubConnected()) {
      this._hubConnection.invoke(hubEvent.name, hubEvent.data);
    }
  }

  startSignalR(): Promise<void> {
    const sessionData: ISession = this.userStore.getSessionStorage();

    if (!this._isHubConnected() && !this._connectionInProgress && !!sessionData) {
      this._connectionInProgress = true;

      this._hubConnection = new HubConnectionBuilder()
        .withUrl(`${this._appConfig.apiBaseUrl}/${this._hubName}`, {
          accessTokenFactory: () => sessionData.token,
          skipNegotiation: false,
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .configureLogging(new SignalRLogger(LogLevel.Error, LogLevel.Critical, this.applicationInsightsService))
        .build();

      this._subscribeToEvents();
      this._handleDisconnect();

      return this._hubConnection.start().then(
        () => {
          this._retryCount = 0;
          this._connectionInProgress = false;
          this.connected$.next(true);
        },
        error => {
          this._connectionInProgress = false;
          error = error || new Error('SignalR connection was failed with unknown reason');
          const errorLogCustomProperties = {
            errorType: error.errorType || typeof error,
          };
          this.applicationInsightsService.logException(error, errorLogCustomProperties);

          switch (error.statusCode) {
            case 401:
              this.authService.refreshToken().subscribe();
              break;
            case 0:
            case undefined:
            case null:
              /* Temporary fix to get 401 error on negotiation connect until
                 github issue (https://github.com/dotnet/aspnetcore/issues/39079) will be closed (version 7 or 8)
               */
              if (error.message && error.message.includes('Status code \'401\'')) {
                this.authService.refreshToken().subscribe();
                break;
              } else {
                const delayInMilliseconds = DEFAULT_RETRY_DELAYS_IN_MILLISECONDS[this._retryCount];
                if (delayInMilliseconds) {
                  this._retryCount++;
                  setTimeout(() => {
                    this._reconnectToHub();
                  }, delayInMilliseconds);
                  break;
                } else {
                  this.authService.unauthorize();
                  break;
                }
              }
            default:
              this.authService.unauthorize();
          }
        },
      );
    }
  }

  stopSignalR(): Promise<void> {
    if (this._isHubConnected()) {
      this.connected$.next(false);
      return this._hubConnection.stop();
    } else {
      return new Promise(resolve => {
        resolve();
      });
    }
  }

  private _subscribeToEvents(): void {
    this._events.forEach(event => {
      this._setupHubEventListener(event);
    });
  }

  private _reconnectToHub(): void {
    console.log(`SignalR connection lost.. Attempting to reconnect.. Tries: ${this._retryCount}`);
    this.startSignalR();
  }

  private _isHubConnected(): boolean {
    return this._hubConnection && this._hubConnection.state === HubConnectionState.Connected;
  }

  private _handleDisconnect(): void {
    this._handleWindowOnlineEvent();
    this._handleWindowOfflineEvent();
  }

  private _handleWindowOnlineEvent(): void {
    if (!this._isSubscribedToOnlineEvent) {
      this._isSubscribedToOnlineEvent = true;
      window.addEventListener('online', () => {
        this.authService.tokenResolverChecked$
          .pipe(
            filter(refreshed => refreshed),
            take(1),
          )
          .subscribe(() => {
            this._reconnectToHub();
          });
      });
    }
  }

  private _handleWindowOfflineEvent(): void {
    if (!this._isSubscribedToOfflineEvent) {
      this._isSubscribedToOfflineEvent = true;
      window.addEventListener('offline', () => {
        this.stopSignalR();
      });
    }
  }

  private _setupHubEventListener(name: THubEventName): void {
    this._hubConnection.on(name, (data: any) => {
      this.eventBus$$.next({
        name,
        data,
      });

      const isFileUploadEvent = [
        THubMethods.TechPhotosUploaded,
        THubMethods.DocsUploaded,
        THubMethods.MyDocsUploaded,
        THubMethods.ProtectionPlanDocsUploaded,
      ].includes(name);

      if (data.errorMessage) {
        this.notification.next({
          message: isFileUploadEvent ? 'Files uploading failed.' : data.errorMessage,
          type: NotificationType.Error,
        });
      }
    });
  }
}
