<div class="portal-loader">
  <div class="portal-loader__logo-wrapper">
    <div>
      <img class="portal-loader__logo" [src]="logoUrl" alt="">
    </div>
  </div>
  <div class="portal-loader__message">
    <span>Redirecting...</span>
  </div>
</div>
