import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig,  MatDialogRef } from '@angular/material/dialog';
import { Md5 } from 'ts-md5';

import { ApplicationInsightsService } from '@core/services/application-insights/application-insights.service';

import { ModalDialogComponent } from './modal-dialog.component';
import { IModalDialog } from './modal-dialog.interface';

@Injectable({ providedIn: 'root' })
export class ModalDialogService {
  readonly config: MatDialogConfig = {
    scrollStrategy: this.overlay.scrollStrategies.block(),
    hasBackdrop: true,
    width: '520px',
    height: 'auto',
    maxWidth: 'calc(100vw - 40px)',
    panelClass: ['_dialog_full-size'],
    disableClose: false,
    autoFocus: 'dialog',
  };

  private readonly _modalsQueue = new Set();

  constructor(
    private readonly dialog: MatDialog,
    private readonly overlay: Overlay,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {}

  /**
   * Opens a modal from component
   */
  open<T, R = any>(
    data: IModalDialog,
    component: ComponentType<any> = ModalDialogComponent,
    config: MatDialogConfig = {},
  ): MatDialogRef<T, R> {
    component = component || ModalDialogComponent;
    return this._open(data, component, config);
  }

  /**
   * Opens a modal from template
   */
  openFromTemplate<T>(data: IModalDialog, template: TemplateRef<T>, config: MatDialogConfig = {}): MatDialogRef<T> {
    return this._open(data, template, config);
  }

  /**
   * Base function that contains preOpen and afterOpen logic
   * If the same modal is already opened - skips the new one
   */
  private _open<T>(
    data: IModalDialog,
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig,
  ): MatDialogRef<T> {
    const hash = this._buildHash(data);
    const componentConfig = data.config || {};
    if (this._modalsQueue.has(hash)) {
      console.log('ModalsService', 'Modal is already opened, skipping a new one', data.title);
      return;
    }
    this.applicationInsightsService.logEvent('ModalDialogOpened', {
      data,
    });
    this._modalsQueue.add(hash);
    const dialog = this.dialog.open(componentOrTemplateRef, {
      ...this.config,
      ...config,
      data: {
        ...data,
        config: {
          ...componentConfig,
        },
      },
    });
    dialog.afterClosed().subscribe(() => this._modalsQueue.delete(hash));
    return dialog;
  }

  /**
   * Builds a uniq hash for the specific modal
   */
  private _buildHash(data: IModalDialog): string | Int32Array {
    const hashData = { title: data.title };
    return Md5.hashStr(JSON.stringify(hashData));
  }
}
