import { FA_FORM_FIELD_DEFAULT_OPTIONS } from '@core/providers/material/form-field.provider';
import { FA_TOOLTIPS_CONFIG } from '@core/providers/material/tooltip.provider';

import { FA_CHECKBOX_DEFAULT_OPTIONS } from './checkbox.provider';
import { FA_RADIO_DEFAULT_OPTIONS } from './radio-button.provider';
import { FA_TABS_CONFIG } from './tabs.provider';

export const FA_MATERIAL_PROVIDERS = [
  FA_RADIO_DEFAULT_OPTIONS,
  FA_CHECKBOX_DEFAULT_OPTIONS,
  FA_TABS_CONFIG,
  FA_FORM_FIELD_DEFAULT_OPTIONS,
  FA_TOOLTIPS_CONFIG,
];
