import { ISession, Timezone } from '../../interfaces/session/session-data.interface';
import { IUserRole } from '../../interfaces/user/role.interface';

export namespace StoreState {
  export interface IStoreState {
    session: ISession;
    timezones: Timezone[];
    roles: IUserRole[];
    loading: boolean;
    saveCurrentUserInProgress: boolean;
    showFive9Chat: boolean;
  }

  export const initialState: IStoreState = {
    session: null,
    timezones: [],
    roles: [],
    loading: false,
    saveCurrentUserInProgress: false,
    showFive9Chat: false,
  };
}
