import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { IAppConfig } from '@core/interfaces/app-config.interface';

import { AuthService } from './auth.service';

@Injectable()
export class TenantNameResolverService {
  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
    private readonly authService: AuthService,
  ) {}

  resolveTenantName(): string {
    let { tenant } = this.authService.getPortalUrlData();
    if (this.authService.isAuthorized()) {
      tenant = this.authService.getTenantVanityUrlIdentifier();
    }

    return tenant ? tenant : this.appConfig.commonTenantName;
  }
}
