import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenRefreshResolver implements Resolve<number> {
  constructor(private authService: AuthService) {}

  resolve() {
    return this.authService.setupTokenRenewRoutine();
  }
}
