import { InjectionToken } from '@angular/core';

import { IAppConfig } from '@core/interfaces/app-config.interface';

interface IAppMobileDetect {
  isMobileDevice: boolean;
  userAgentData: boolean;
  isMobileByUserAgent: boolean;
  isSmallTouchDevice: boolean;
  userAgent: string;
}

export const APP_CONFIG: InjectionToken<IAppConfig> = new InjectionToken<IAppConfig>('APP_CONFIG');

export const APP_MOBILE_DETECT: InjectionToken<IAppMobileDetect> = new InjectionToken<IAppMobileDetect>('APP_MOBILE_DETECT');
