import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@core/constants/app-config.constants';

import { IDataSearch } from '../interfaces/data-search.interface';
import { BaseSearchRequest } from '../models/search/search-request.model';

export abstract class CrudApiBaseService<T> {
  protected readonly httpClient = inject(HttpClient);
  private readonly appConfig = inject(APP_CONFIG);
  protected readonly apiUrl = `${this.appConfig.apiBaseUrl}/api/`;
  abstract readonly endpoint: string;

  getUrl(url: string = ''): string {
    return `${this.apiUrl}${this.endpoint}${url ? `/${url}` : ''}`;
  }

  all(searchRequest: BaseSearchRequest) {
    return this.httpClient.post<IDataSearch<T>>(this.getUrl('all'), searchRequest);
  }

  get(id: number): Observable<T> {
    return this.httpClient.get<T>(this.getUrl(`${id}`));
  }

  create(entity: T): Observable<T> {
    return this.httpClient.post<T>(this.getUrl(''), entity);
  }

  update(entity: Partial<T>) {
    return this.httpClient.put<T>(this.getUrl(`${entity['id']}`), entity);
  }

  remove(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.getUrl(`${id}`));
  }

  removeMany(ids: number[]): Observable<void> {
    return this.httpClient.request<void>('delete', this.getUrl('deletemany'), { body: ids });
  }

  search(searchRequest: string): Observable<T[]> {
    return this.httpClient.get<T[]>(this.getUrl(`search?searchString=${encodeURIComponent(searchRequest)}`));
  }
}
