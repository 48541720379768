import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { IModalDialog } from './modal-dialog.interface';

@Component({
  selector: 'app-modal-dialog',
  standalone: true,
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    NgIf,
    CustomOverlayScrollbarDirective,
    MatButtonModule,
    LoadingButtonDirective,
    MatDialogModule,
  ],
})
export class ModalDialogComponent implements OnInit {
  @Input()
    data: IModalDialog;

  @Input()
    dialogRef: MatDialogRef<ModalDialogComponent>;

  @Input()
    hasClose = false;

  @Input()
    disabledActionBtn = false;

  @Input()
    actionInProgress = false;

  @Input()
    showActions = true;

  @Output()
    actionBtnClick = new EventEmitter<void>();

  private _clickHandlerFn: Function;

  constructor(
  @Optional() @Inject(MAT_DIALOG_DATA) dialogData: IModalDialog,
    @Optional() dialogRef: MatDialogRef<ModalDialogComponent>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    const data = dialogData || <IModalDialog>{};
    this.data = { ...data };
    if (dialogRef) {
      this.dialogRef = dialogRef;
    }
  }

  ngOnInit(): void {
    if (!this.dialogRef.disableClose) {
      this.dialogRef.afterOpened().subscribe(() => {
        this._clickHandlerFn = this._closeDialogOnClickOutside;
      });
    }
  }

  /**
   * Handles click outside of the popup
   * @see _closeDialogOnClickOutside
   * @param event Contains click position on the screen
   */
  @HostListener('document:click', ['$event'])
  clickHandler(event: MouseEvent): void {
    if (!this._clickHandlerFn) {
      return;
    }
    this._clickHandlerFn(event);
  }

  /**
   * Closes the popup dialog
   */
  close(result?: any): void {
    this.dialogRef.close(result);
  }

  /**
   * Handles callback actions
   */
  action(): void {
    const callback = this.data.actionCallback;
    if (!callback) {
      this.actionBtnClick.emit();
      return;
    }

    callback();
    this.close(true);
  }

  /**
   * Handles click outside of the popup
   * The native backdrop is disabled to allow interaction with top menu in one click
   * Because of that we cannot use native backdrop click handler here
   * @param event Contains click position on the screen
   */
  private _closeDialogOnClickOutside(event: MouseEvent): void {
    const matDialogContainerEl = this._getDialogContainerEl();
    if (!matDialogContainerEl.contains(<Node>event.target)) {
      this.dialogRef.close();
    }
  }

  /**
   * Correctly handles usage of <app-modal-dialog> in custom dialog components
   * @example
   * <app-modal-dialog>Custom content template</app-modal-dialog>
   */
  private _getDialogContainerEl(): HTMLElement {
    const el = this.viewContainerRef.element.nativeElement.parentElement;
    if (el.tagName !== 'MAT-DIALOG-CONTAINER') {
      return el.parentElement;
    }
    return el;
  }
}
