import { Injectable } from '@angular/core';

import { Tenant } from '../interfaces/tenant/tenant.interface';
import { Theme } from '../interfaces/ui/theme.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({ providedIn: 'root' })
export class TenantApi extends CrudApiBaseService<Tenant> {
  endpoint = 'tenant';

  info(tenant: string | number) {
    const endpointUrl = Number.isNaN(+tenant) ? `info?tenantName=${tenant}` : `info/id?tenantId=${tenant}`;
    return this.httpClient.get<Theme>(this.getUrl(endpointUrl));
  }

  infoByClaimPortalToken(encryptedClaimPortalToken: string) {
    return this.httpClient.get<Theme>(this.getUrl('getByCrmClaimToken'), {
      params: {
        encryptedClaimPortalToken,
      },
    });
  }

  addPortal(tenantId: number) {
    return this.httpClient.put<boolean>(this.getUrl(`${tenantId}/add-to-portal`), {});
  }

  getFeatureAvailability(tenantId: number, featureId: number) {
    return this.httpClient.get<boolean>(this.getUrl(`${tenantId}/feature/${featureId}`), {});
  }

  setFeatureAvailability(tenantId: number, featureId: number, value: boolean) {
    return this.httpClient.put<boolean>(this.getUrl(`${tenantId}/feature/${featureId}/${value}`), {});
  }
}
