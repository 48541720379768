import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appCustomOverlayScrollbar]',
  standalone: true,
})
export class CustomOverlayScrollbarDirective {

  @HostBinding('class')
    elementClass = 'custom-overlay-scrollbar';

  constructor() {}
}
