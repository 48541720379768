import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TConsumerPlanType } from '../enums/consumer-plan-type.enum';
import { IUserProfile, User } from '../interfaces/user/user.interface';
import { ChangePassword } from '../interfaces/user-profile/change-password.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({ providedIn: 'root' })
export class UserProfileApi extends CrudApiBaseService<any> {
  endpoint = 'userprofile';

  me(): Observable<any> {
    return this.httpClient.get<IUserProfile>(this.getUrl('me'));
  }

  update(credentials: any) {
    return this.httpClient.put<User>(this.getUrl('me'), credentials);
  }

  changepassword(credentials: ChangePassword) {
    return this.httpClient.put<any>(this.getUrl('changepassword'), credentials);
  }

  requestPasswordReset(email: string) {
    return this.httpClient.get<boolean>(this.getUrl(`requestpasswordreset?email=${encodeURIComponent(email)}`));
  }

  requestAccountActivation(email: string, url: string) {
    return this.httpClient.get<boolean>(this.getUrl(`${url}?email=${encodeURIComponent(email)}`));
  }

  updateDefaultClaimType(claimType: TConsumerPlanType) {
    return this.httpClient.put<void>(this.getUrl(`defaultclaimtype?claimType=${claimType}`), {});
  }

  putAcknowledgeRegistration() {
    return this.httpClient.put<void>(this.getUrl('acknowledge-registration'), {});
  }

  updateEnablingNotifications(userId: string | number, notificationsEnabled: boolean) {
    return this.httpClient.put<void>(this.getUrl(`notificationsenabled?userId=${userId}&notificationsEnabled=${notificationsEnabled}`), {});
  }
}
