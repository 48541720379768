import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
} from '@angular/material/legacy-form-field';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  color: 'primary',
  appearance: 'standard',
};

const matFormFieldDefaultOptionsTechPortal: MatFormFieldDefaultOptions = {
  color: 'primary',
  appearance: 'outline',
};

export const FA_FORM_FIELD_DEFAULT_OPTIONS = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: matFormFieldDefaultOptions,
};

export const TECH_PORTAL_FORM_FIELD_DEFAULT_OPTIONS = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: matFormFieldDefaultOptionsTechPortal,
};
