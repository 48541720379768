import { inject, Injectable } from '@angular/core';
import { CanMatch, Router, UrlTree } from '@angular/router';

import { TPage } from '../enums/page.enum';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanMatch {
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(): UrlTree | boolean {
    if (!this.authService.isAuthorized()) {
      return this.router.createUrlTree([TPage.LogOut]);
    }
    return true;
  }
}

export const authGuard = (): UrlTree | boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isAuthorized()) {
    return router.createUrlTree([TPage.LogOut]);
  }
  return true;
};
