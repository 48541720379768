export enum TDocumentType {
  /// <summary>
  /// Technician uploaded photo
  /// </summary>
  TechPhoto = 1,
  /// <summary>
  /// Technician uploaded report
  /// </summary>
  TechReport = 2,
  /// <summary>
  /// Photo
  /// </summary>
  Photo = 3,
  /// <summary>
  /// Customer uploaded photo
  /// </summary>
  CustPhoto = 4,
  /// <summary>
  /// So far unknown
  /// </summary>
  CustPhoto0rd = 5,
  /// <summary>
  /// My service portal upload photo
  /// </summary>
  myServPhotos = 6,
  /// <summary>
  /// Receipt was uploaded
  /// </summary>
  Receipt = 7,
  /// <summary>
  /// Service request
  /// </summary>
  ServiceRequest = 8,
  /// <summary>
  /// eServiceRequest
  /// </summary>
  eServiceRequest = 9,
  /// <summary>
  /// Signed cancellation agreement
  /// </summary>
  SignedCancellationAgreement = 10,
  /// <summary>
  /// Signed refund agreement
  /// </summary>
  SignedRefundAgreement = 11,
  /// <summary>
  ///  Consumer Purchase Receipt
  /// </summary>
  ConsumerPurchaseReceipt = 12,
  /// <summary>
  /// Manufacture Invoice Receipt
  /// </summary>
  ManufacturerInvoiceReceipt = 13,
  /// <summary>
  /// Delivery Receipt is documentation
  /// the furniture ordered/invoicing
  /// Montage for was delivered and
  /// contains the Consumers signature
  /// </summary>
  DeliveryReceipt = 14,
  TechDocument = 19,
  Plan = 122,
  TechInvoice = 136,
  TechReportWorkOrder = 137,

  TechServicePhoto = 145,
  TechProductPhoto = 146,
  TechTagPhoto = 147,
  TechDamagePhoto = 148,
  RetailerPortalUploadedPhoto = 149,

  _UploadedReceipt = '_UploadedReceipt',
  _ProductPhoto = 4, // CustPhoto alias
  _DamagePhoto = 401, // CustPhoto alias
}
