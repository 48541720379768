import { StorageItem } from '../enums/storage-item.enum';

const generateStorageKey = (item: StorageItem) => `__MONTAGE__${item}`;

export class AppStorage {
  static get(item: StorageItem) {
    return JSON.parse(localStorage.getItem(generateStorageKey(item)));
  }

  static set(item: StorageItem, value: any) {
    return localStorage.setItem(generateStorageKey(item), JSON.stringify(value));
  }

  static remove(item: StorageItem) {
    localStorage.removeItem(generateStorageKey(item));
  }
}
