import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize, withLatestFrom } from 'rxjs/operators';

import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { LookupApiService } from '../../api/lookup-api.service';
import { UserProfileApi } from '../../api/user-profile.api';
import { TConsumerPlanType } from '../../enums/consumer-plan-type.enum';
import { StorageItem } from '../../enums/storage-item.enum';
import { ISession } from '../../interfaces/session/session-data.interface';
import { AppStorage } from '../../utils/storage';
import { BaseStore } from '../_base/base.store';
import { StoreState } from './user.state';
import initialState = StoreState.initialState;
import IStoreState = StoreState.IStoreState;
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { ThemingService } from '../../services/theming.service';

@Injectable({
  providedIn: 'root',
})
export class UserStore extends BaseStore<IStoreState> {
  constructor(
    private readonly userProfileApi: UserProfileApi,
    private readonly notificationService: NotificationService,
    private readonly lookupApiService: LookupApiService,
    private readonly themingService: ThemingService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly modalDialogService: ModalDialogService,
  ) {
    super(initialState);
    this.initSession();
  }

  init(): void {
    this.initSession();
    this.getTimezoneList();
    this.lookupUserRoles();
  }

  getSession(): ISession {
    return this.get('session');
  }

  getSessionStorage(): ISession {
    return AppStorage.get(StorageItem.Session);
  }

  setSession(session: ISession): void {
    const theme = {
      accentColorARGB: session.accentColorARGB,
      secondaryAccentColorARGB: session.secondaryAccentColorARGB,
      bgColorARGB: session.bgColorARGB,
    };

    session = {
      ...session,
      ...this.themingService.parseThemeColors(theme),
    };
    this.themingService.setTheme(session);

    AppStorage.set(StorageItem.Session, session);
    this.updateState({
      session,
    });
  }

  updateSession(session: Partial<ISession>): void {
    const currentSession = this.get('session');
    const mergedSession = {
      ...currentSession,
      ...session,
    };

    AppStorage.set(StorageItem.Session, mergedSession);
    this.updateState({
      session: mergedSession,
    });
  }

  clearXTenantId(): void {
    const currentSession = this.get('session');
    this.updateState({
      session: {
        ...currentSession,
        XTenantId: null,
      },
    });
  }

  updateCurrentUser(value: {firstName: string; lastName: string; timeZoneId: number; phoneNumber: string; notificationsEnabled: boolean}): void {
    this.updateState({ saveCurrentUserInProgress: true });
    this.userProfileApi
      .update(value)
      .pipe(
        withLatestFrom(this.select$('session'), this.select$('timezones')),
        finalize(() => this.updateState({ saveCurrentUserInProgress: false })),
      )
      .subscribe(([updatedUser, session, timezones]) => {
        this.updateUser({
          ...session,
          ...updatedUser,
          userFirstName: updatedUser.firstName,
          userLastName: updatedUser.lastName,
          timeZone: timezones.find(t => t.id === updatedUser.timeZoneId),
          notificationsEnabled: updatedUser.notificationsEnabled,
        });
        this.notificationService.next({
          message: 'Profile was updated successfully.',
          type: NotificationType.Success,
        });
      });
  }

  getCurrentUserProfile() {
    this.updateState({ loading: true });
    return this.userProfileApi
      .me()
      .pipe(
        withLatestFrom(this.select$('session')),
        finalize(() => this.updateState({ loading: false })),
      );
  }

  updateNotificationsSwitcher(userId: string | number, notificationsEnabled: boolean) {
    this.updateState({ saveCurrentUserInProgress: true });
    return this.userProfileApi
      .updateEnablingNotifications(userId, notificationsEnabled)
      .pipe(
        withLatestFrom(this.select$('session')),
        finalize(() => this.updateState({ saveCurrentUserInProgress: false })),
      );
  }

  getTimezoneList(): void {
    this.lookupApiService.lookupTimezones().subscribe(timezones => {
      this.updateState({ timezones });
    });
  }

  updateUser(updatedSession: Partial<ISession>) {
    this.updateSession(updatedSession);
  }

  setDefaultClaimType(claimType: TConsumerPlanType) {
    const { defaultClaimType, XTenantId } = this.get('session');

    if (defaultClaimType === claimType) {
      return;
    }

    this.updateSession({
      defaultClaimType: claimType,
    });

    if (!XTenantId) {
      this.userProfileApi
        .updateDefaultClaimType(claimType)
        .pipe(
          catchError(error => {
            // Reset state
            this.updateSession({
              defaultClaimType,
            });
            return throwError(error);
          }),
        )
        .subscribe();
    }
  }

  private initSession() {
    const session: ISession = AppStorage.get(StorageItem.Session);
    if (session) {
      this.applicationInsightsService.setAIUserId(session.userId?.toString());
      this.setSession(session);
    }
  }

  private lookupUserRoles() {
    const session = this.getSessionStorage();
    this.lookupApiService.lookupRolesByScope(session.scope, session.roles[0]).subscribe(roles => {
      this.updateState({ roles });
    });
  }

  showFive9Chat() {
    this.updateState({
      showFive9Chat: true,
    });
  }

  removeFive9Chat() {
    this.updateState({
      showFive9Chat: false,
    });
  }
}
