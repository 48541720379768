import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ClaimDetails } from '../interfaces/claims/claimDetails.interface';
import { Files } from '../interfaces/claims/files.interface';
import { FileService } from '../services/file.service';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class SystemApiService extends CrudApiBaseService<any> {
  endpoint = 'system';

  constructor(private readonly fileService: FileService) {
    super();
  }

  buildNumber() {
    return this.httpClient.get<string>(this.getUrl('buildnumber'), {});
  }

  findClaimByNumber(claimNumber: string): Observable<ClaimDetails> {
    return this.httpClient.get<ClaimDetails>(this.getUrl('findclaimbynumber'), {
      params: {
        claimNumber,
      },
    });
  }

  findByClaimOrAgreementNumber(documentName: string): Observable<ClaimDetails> {
    return this.httpClient.get<ClaimDetails>(this.getUrl('find-consumer-document'), {
      params: {
        documentName,
      },
    });
  }

  getFilesForClaim(claimId: any) {
    return this.httpClient
      .get<Omit<Files, 'index'>[]>(this.getUrl(`claim/${claimId}/files`))
      .pipe(map(files => this.fileService.sortFiles(files)));
  }

  getFilesForAgreement(planId: any) {
    return this.httpClient
      .get<Omit<Files, 'index'>[]>(this.getUrl(`plan/${planId}/files`))
      .pipe(map(files => this.fileService.sortFiles(files)));
  }

  getArchiveFiles(agreementId: string, claimId: string) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .post<Omit<Files, 'index'>[]>(
          this.getUrl('archive/search'),
          {
            agreementId,
            claimId,
          },
          {
            params: {
              width,
            },
          },
        )
          .pipe(map(files => this.fileService.sortFiles(files))),
      ),
    );
  }

  support(body: {subject: string; body: string}) {
    return this.httpClient.post<void>(this.getUrl('support'), body);
  }
}
