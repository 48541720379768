import { Injectable } from '@angular/core';

import { Feature } from '@core/enums/feature.enum';
import { INavigation } from '@core/interfaces/navigation.interface';

import { UserStore } from '../store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private readonly userStore: UserStore) {}

  allowed(featureId: Feature) {
    const session = this.userStore.getSessionStorage();
    if (session) {
      return (session.features || []).indexOf(featureId) !== -1;
    }
  }

  allowedMany(features: Feature[]): boolean {
    let isAllowed = true;
    for (let i = 0; i < features.length; i++) {
      if (!this.allowed(features[i])) {
        isAllowed = false;
        break;
      }
    }
    return isAllowed;
  }

  allowedSome(features: Feature[]): boolean {
    if (features.length === 0) {
      return true;
    }
    let isAllowed = false;
    features.forEach(feature => {
      if (!isAllowed) {
        isAllowed = this.allowed(feature);
      }
    });
    return isAllowed;
  }

  filterNavigation(navList: INavigation[]) {
    return navList.filter(navItem => {
      if (navItem.child?.length > 0) {
        navItem.child = this.filterNavigation(navItem.child);
      }

      if (navItem.features) {
        if (!!navItem.featureOptions && navItem.featureOptions.some) {
          return this.allowedSome(navItem.features);
        } else {
          return this.allowedMany(navItem.features);
        }
      } else {
        return true;
      }
    });
  }

}
