import { safePluck } from '@rx-angular/state/selections';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';

import { BaseComponent } from '@core/base/base.component';

import { IStore } from './store.interface';

export type ProjectStateFn<T> = (oldState: T) => T;

/**
 * Root class that is used as a based one for all other stores
 */
export abstract class BaseStore<T extends object> extends BaseComponent implements IStore<T> {
  state$: Observable<T>;
  private _state$: BehaviorSubject<T>;

  protected constructor(initialState: T) {
    super();
    this._state$ = new BehaviorSubject(initialState);
    this.state$ = this._state$.asObservable();
  }

  selectRoot$(): Observable<T> {
    return this.state$;
  }

  select$<P1 extends keyof T>(arg1: P1): Observable<T[P1]>;
  select$<P1 extends keyof T, P2 extends keyof T[P1]>(arg1: P1, arg2: P2): Observable<T[P1][P2]>;
  select$<P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2]>(
    arg1: P1,
    arg2: P2,
    arg3: P3
  ): Observable<T[P1][P2][P3]>;
  select$(...args: string[]): any {
    return this.selectRoot$().pipe(filter(Boolean), pluck(...args), distinctUntilChanged());
  }

  get(): T;
  get<P1 extends keyof T>(p1: P1): T[P1];
  get<P1 extends keyof T, P2 extends keyof T[P1]>(p1: P1, p2: P2): T[P1][P2];
  get<P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2]>(p1: P1, p2: P2, p3: P3): T[P1][P2][P3];
  get<P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3]>(
    p1: P1,
    p2: P2,
    p3: P3,
    p4: P4
  ): T[P1][P2][P3][P4];
  get<
    P1 extends keyof T,
    P2 extends keyof T[P1],
    P3 extends keyof T[P1][P2],
    P4 extends keyof T[P1][P2][P3],
    P5 extends keyof T[P1][P2][P3][P4]
  >(p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): T[P1][P2][P3][P4][P5];
  get<
    P1 extends keyof T,
    P2 extends keyof T[P1],
    P3 extends keyof T[P1][P2],
    P4 extends keyof T[P1][P2][P3],
    P5 extends keyof T[P1][P2][P3][P4],
    P6 extends keyof T[P1][P2][P3][P4][P5]
  >(p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): T[P1][P2][P3][P4][P5][P6];
  get<
    P1 extends keyof T,
    P2 extends keyof T[P1],
    P3 extends keyof T[P1][P2],
    P4 extends keyof T[P1][P2][P3],
    P5 extends keyof T[P1][P2][P3][P4],
    P6 extends keyof T[P1][P2][P3][P4][P5]
  >(
    ...properties: [P1] | [P1, P2] | [P1, P2, P3] | [P1, P2, P3, P4] | [P1, P2, P3, P4, P5] | [P1, P2, P3, P4, P5, P6]
  ): T | T[P1] | T[P1][P2] | T[P1][P2][P3] | T[P1][P2][P3][P4] | T[P1][P2][P3][P4][P5] | T[P1][P2][P3][P4][P5][P6] {
    if (!!properties && properties.length > 0) {
      return safePluck(this._state$.getValue(), properties);
    } else {
      return this._state$.getValue();
    }
  }

  updateState(newState: Partial<T>): void;
  updateState(stateFunction: ProjectStateFn<T>): void;
  updateState(newStateOrStateFunction: Partial<T> | ProjectStateFn<T>) {
    if (typeof newStateOrStateFunction === 'function') {
      this._state$.next(newStateOrStateFunction(this.get()));
    } else {
      this._state$.next({ ...this.get(), ...newStateOrStateFunction });
    }
  }
}
