<div class="notification"
     [ngClass]="{
     'notification--show': show,
     'notification--error': notificationType === types.Error,
     'notification--success': notificationType === types.Success
     }">
  <div class="notification__message">
    {{message}}
  </div>
  <mat-icon class="notification__close" svgIcon="close" (click)="close()"></mat-icon>
</div>
