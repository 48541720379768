import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,Resolve } from '@angular/router';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';
import { IAppConfig } from '@core/interfaces/app-config.interface';
import { AuthService } from '@core/services/auth.service';

import { WINDOW } from '../providers/window.provider';
import { ThemingService } from '../services/theming.service';

@Injectable({ providedIn: 'root' })
export class TenantThemeResolver implements Resolve<any> {
  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
    private themingService: ThemingService,
    private authService: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let { tenant } = this.authService.getPortalUrlData();
    const href = this.window.location.href;
    const tenantFromParams = route.paramMap.get('tenant');
    const page = /[^/]*$/.exec(href)[0];
    const pageDefined = !!page && tenantFromParams !== page;
    if (tenant && tenant !== tenantFromParams) {
      const replace = `/${tenant}${!pageDefined ? (page ? '/login' : 'login') : ''}`;
      this.window.location.href = href.replace(`/${tenantFromParams}`, `${replace}`);
    }
    if (!tenant) {
      tenant = tenantFromParams;
    }

    if (tenant === this.appConfig.commonTenantName && route.queryParams.tenantId) {
      tenant = route.queryParams.tenantId;
    }

    if ([TPortalType.TechPortal, TPortalType.MJC].includes(this.appConfig.portalType) && tenant === 'common') {
      tenant = this.appConfig.commonTenantName;
    }

    return this.themingService.for(tenant);
  }
}
