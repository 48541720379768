/// <summary>
/// Claim stages to use in portal - NOTE: not a 1-1 with CRM stages
/// </summary>
export enum ClaimStages {
  /// <summary>
  /// Pending
  /// </summary>
  Pending = 1,
  /// <summary>
  /// Intake
  /// </summary>
  Intake = 2,
  /// <summary>
  /// Documentation Pending
  /// </summary>
  DocumentationPending = 3,
  /// <summary>
  /// Validation
  /// </summary>
  Validation = 4,
  /// <summary>
  /// Servicing
  /// </summary>
  Servicing = 5,
  /// <summary>
  /// Not covered (denied)
  /// </summary>
  NotCovered = 6,
  /// <summary>
  /// Under review (review requested)
  /// </summary>
  UnderReview = 7,
  /// <summary>
  /// Documentation review
  /// </summary>
  DocumentationReview = 8,
  /// <summary>
  /// Agreement Validation
  /// </summary>
  AgreementValidation = 9,
  /// <summary>
  /// Unknown/Not Applicable
  /// </summary>
  NA = 99,
  /// <summary>
  /// Complete
  /// </summary>
  Complete = 100,
}
