import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { NotificationErrorComponent } from './components/notification-error/notification-error.component';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { NotificationWarningComponent } from './components/notification-warning/notification-warning.component';

@NgModule({
  declarations: [NotificationErrorComponent, NotificationPopupComponent, NotificationWarningComponent],
  exports: [NotificationErrorComponent, NotificationPopupComponent, NotificationWarningComponent],
  imports: [MatIconModule, NgClass],
})
export class NotificationModule {}
