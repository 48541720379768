import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoadingButton]',
  standalone: true,
})
export class LoadingButtonDirective implements AfterViewInit {
  @Input() set isLoading(isLoading: boolean) {
    this._setLoadingClass(isLoading);
  }
  @Input() resetAfterLoad = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const div = this.renderer.createElement('div');
    div.classList.add('_common-loader');

    this.renderer.appendChild(this.elementRef.nativeElement, div);
  }

  private _setLoadingClass(isLoading: boolean): void {
    if (isLoading) {
      this.elementRef.nativeElement.classList.add('_common-button_is-loading');
      this.elementRef.nativeElement.setAttribute('disabled', 'disabled');
    } else {
      this.elementRef.nativeElement.classList.remove('_common-button_is-loading');
      if (this.resetAfterLoad) {
        this.elementRef.nativeElement.removeAttribute('disabled');
      }
    }
  }
}
