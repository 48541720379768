import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

import { TPage } from '@core/enums/page.enum';
import { AuthService } from '@core/services/auth.service';
import { UserStore } from '@core/store/user/user.store';

@Component({
  selector: 'app-not-found',
  standalone: true,
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
  ],
})
export class NotFoundComponent {
  buttonCaption = 'GO TO PORTAL';
  constructor(private router: Router, private authService: AuthService, private readonly userStore: UserStore) {
    this.buttonCaption = this.authService.isAuthorized() ? 'GO TO PORTAL' : 'GO TO LOGIN';
    if (this.router.url.includes(`${TPage.NotFound}`)) {
      this.setTenantPrimaryStyles();
    }
  }

  goHome(): void {
    if (this.authService.isAuthorized()) {
      this.router.navigate(this.authService.getAllowedInitialState());
    } else {
      this.router.navigate([TPage.LogOut]);
    }
  }

  setTenantPrimaryStyles(): void {
    const session = this.userStore.get('session');
    if (session) {
      const css = `.color-primary {
                      background-color: ${session.hexAccentColor} !important;
                    }`;
      const styleSheet = document.createElement('style');
      styleSheet.innerText = css;
      document.head.appendChild(styleSheet);
    }
  }
}
