import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NotificationType } from '../enums/notification-type.enum';
import { NotificationInterface } from '../interfaces/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notification$: Subject<NotificationInterface> = new Subject<NotificationInterface>();
  private skipErrorEndpoints: string[] = [];

  constructor() {}

  next(notification: NotificationInterface) {
    this.notification$.next(notification);
  }

  nextError(httpError: HttpErrorResponse, request: HttpRequest<any>) {
    const error: any = httpError.error;
    let skip = false;

    this.skipErrorEndpoints = this.skipErrorEndpoints.filter(endpoint => {
      if (request.url.includes(endpoint)) {
        skip = true;
        return false;
      } else {
        return true;
      }
    });

    if (skip) {
      return;
    }

    if (httpError.status >= 500) {
      const message = (httpError.error?.Error?.length || httpError.error?.error?.length)
        ? (httpError.error?.Error || httpError.error?.error)
        : 'An unexpected error has occurred.';
      this.notification$.next({
        message,
        type: NotificationType.Error,
        duration: 10000,
      });
      // this.appInsightsService.trackException(
      //   {
      //     name: httpError.name,
      //     message: httpError.error?.Error || httpError.message,
      //   },
      //   null,
      //   {
      //     url: request.url,
      //     body: `${request.serializeBody()}`,
      //     method: request.method,
      //     params: request.params.toString(),
      //   },
      //   {},
      //   SeverityLevel.Critical
      // );
      return;
    }

    if (!error) {
      return;
    }

    // Handle plain string scenario
    if (typeof error === 'string') {
      let duration = 3000;
      if (error.length > 100) {
        duration = 6000;
      }
      this.notification$.next({ message: error, type: NotificationType.Error, duration });
      return;
    }

    // Handle scenario when error is returned as [{code, description}]
    if (error[0] && error[0].code) {
      const result = [];

      for (let i = 0; i < error.length; i++) {
        result.push(error[i].description);
      }

      this.notification$.next({ message: result.join('\n'), type: NotificationType.Error });
      return;
    }

    // Handle scenario when error is returned as {Error, Code}
    if (error.Error) {
      this.notification$.next({ message: error.Error, type: NotificationType.Error });
      return;
    }

    if (error.message) {
      this.notification$.next({ message: error.message, type: NotificationType.Error, duration: 6000 });
    }
  }

  skipError(endpoint: string) {
    this.skipErrorEndpoints.push(endpoint);
  }
}
