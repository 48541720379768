import { Injectable } from '@angular/core';

import { BaseHub } from '@core/services/hubs/base.hub';

@Injectable({
  providedIn: 'root',
})
export abstract class MainHub {
  public abstract subscribeToUpdates();
  public abstract startSignalR();

  protected constructor(public readonly baseHub: BaseHub) {}
}
