import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { NotificationType } from '../../enums/notification-type.enum';
import { NotificationInterface } from '../../interfaces/notification.interface';
import { NotificationService } from '../../services/notification.service';

export const AUTO_CLOSE_TIMEOUT = 3000;

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPopupComponent implements OnInit {
  show = false;
  message: string;
  notificationType: NotificationType;
  types = NotificationType;

  private autoHideTimeoutId;

  constructor(private notificationService: NotificationService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.notificationService.notification$.subscribe(notification => this.showMessage(notification));
  }

  close() {
    if (this.autoHideTimeoutId) {
      clearTimeout(this.autoHideTimeoutId);
    }
    this.show = false;
  }

  private showMessage(notification: NotificationInterface) {
    this.message = notification.message;
    this.notificationType = notification.type || NotificationType.Common;
    const duration = notification.duration || AUTO_CLOSE_TIMEOUT;
    if (this.autoHideTimeoutId) {
      clearTimeout(this.autoHideTimeoutId);
    }
    this.show = true;
    this.autoHideTimeoutId = setTimeout(() => {
      this.show = false;
      this.cdr.detectChanges();
    }, duration);
    this.cdr.detectChanges();
  }
}
