import { inject, Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class NonAuthGuard implements CanMatch {
  constructor(private authService: AuthService, private readonly router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]) {
    if (this.authService.isAuthorized()) {
      return this.router.createUrlTree(this.authService.getAllowedInitialState());
    } else {
      return true;
    }
  }
}

export const nonAuthGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isAuthorized()) {
    return router.createUrlTree(authService.getAllowedInitialState());
  } else {
    return true;
  }
};
