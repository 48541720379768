import { ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { buildVariables } from '@th-common/assets/config/build-variables';

import { DIALOG_ROUTE_CONFIG } from '@core/constants/app.constants';
import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { VersionService } from '@core/services/version.service';

import { FA_MATERIAL_PROVIDERS } from './providers/material';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { SignalRResolver } from './resolvers/signalr.resolver';
import { TokenRefreshResolver } from './resolvers/token-refresh.resolver';
import { ApplicationInsightsErrorHandler } from './services/application-insights/application-insights-error-handler';
import { BaseHub } from './services/hubs/base.hub';
import { TenantNameResolverService } from './services/tenant-name-resolver.service';

const PROD_PROVIDERS = !isDevMode()
  ? [
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler,
    },
  ]
  : [];

@NgModule({
  providers: [
    ...PROD_PROVIDERS,
    BaseHub,
    {
      provide: APP_FORM_FIELD_PROVIDER,
      useValue: {
        showErrors: true,
      },
    },
    ...FA_MATERIAL_PROVIDERS,
    SignalRResolver,
    TenantNameResolverService,
    TokenRefreshResolver,
    WINDOW_PROVIDERS,
    {
      provide: DIALOG_ROUTE_CONFIG,
      useValue: {
        animationTime: 333,
      },
    },
  ],
})
export class CoreModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly versionUpdateService: VersionService,
  ) {
    this.versionUpdateService.startVersionPolling();
    this._loadIconsSprite();
  }

  /**
   * Adds custom icons to the sprite
   */
  private _loadIconsSprite(): void {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(buildVariables.svgSpritePath));
  }
}
