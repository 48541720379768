import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TFileBreakpoint } from '../enums/breakpoint.enum';
import { Files } from '../interfaces/claims/files.interface';
import { BreakpointService } from './breakpoint.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly breakpointService: BreakpointService) {}

  getFileViewerWidth() {
    return combineLatest([this.breakpointService.fromDesktop$, this.breakpointService.fromTablet$]).pipe(
      take(1),
      map(([fromDesktop, fromTablet]) => {
        if (fromDesktop) {
          return TFileBreakpoint.Desktop;
        } else if (fromTablet) {
          return TFileBreakpoint.Tablet;
        } else {
          return TFileBreakpoint.Mobile;
        }
      }),
    );
  }

  sortFiles(files: Omit<Files, 'index'>[]) {
    return files.sort((a, b) => {
      if (new Date(a.dateCreated).getTime() > new Date(b.dateCreated).getTime()) {
        return -1;
      }
      if (new Date(a.dateCreated).getTime() < new Date(b.dateCreated).getTime()) {
        return 1;
      }
      return 0;
    });
  }
}
