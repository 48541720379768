import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { firstValueFrom, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { APP_CONFIG } from '@core/constants/app-config.constants';
import { IAppConfig } from '@core/interfaces/app-config.interface';

import { AuthService } from '../services/auth.service';
import { MainHub } from '../services/hubs/main.hub';

@Injectable()
export class SignalRResolver implements Resolve<boolean> {
  constructor(
    private readonly mainHub: MainHub,
    private readonly authService: AuthService,
    @Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
  ) {}

  resolve() {
    if (this.appConfig.skipSignalR) {
      return firstValueFrom(of(true));
    } else {
      return firstValueFrom(this.authService.tokenResolverChecked$
        .pipe(
          filter(tokenResolverChecked => tokenResolverChecked),
          switchMap(() => {
            this.mainHub.startSignalR();
            return this.mainHub.baseHub.connected$.pipe(filter(isConnected => isConnected));
          }),
          take(1),
        ));
    }
  }
}
