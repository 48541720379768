export enum TServiceActionTileStage {
  /// <summary>
  /// Order Parts
  /// </summary>
  OrderParts = 1,
  /// <summary>
  /// Confirm Availability
  /// </summary>
  OrderReplacements,
  /// <summary>
  /// Enter Delivery and Request Payment
  /// </summary>
  EnterDelivery,
  /// <summary>
  /// Expiring soon
  /// </summary>
  ExpiringSoon,
  /// <summary>
  /// Clarification Requests
  /// </summary>
  ClarificationRequests,
  /// <summary>
  /// Ready to Ship
  /// </summary>
  ReadyToShip,
  /// <summary>
  /// Confirm Availability
  /// </summary>
  ConfirmAvailability,
  /// <summary>
  /// Repair Authorized
  /// </summary>
  RepairAuthorized,
  /// <summary>
  /// Replacement Authorized
  /// </summary>
  ReplacementAuthorized,
}
